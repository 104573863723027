<template>
    <div class="xsfl-contrast-box">
        <div class="xsfl-contrast">
            <div class="font-icon" @click="closeAlert">
                <span class="iconfont">&#xe67d;</span>
            </div>
            <div v-if="tableClickName === 'hjxsfl'" style="width 100%;height:240px;" id="xsflContrastChild"></div>
            <div v-if="tableClickName === 'hjxsje'" style="width 100%;height:240px;" id="xsjeContrastChild"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabListData: {},
            childXsflSlotArr: [],
        };
    },
    props: {
        tableClickName: {
            type: String,
        },
        tableClickData: {
            type: Object,
        },
    },
    mounted() {
        const seriseDataFl = [];
        const seriseDataJe = [];
        this.tableClickData.list.forEach(item => {
            this.childXsflSlotArr.push(item.date);
            seriseDataFl.push(item.xsfl);
            seriseDataJe.push(item.xsje);
        });
        if (this.tableClickName === 'hjxsfl') {
            this.xsflContrastChildFn(seriseDataFl);
        } else {
            this.xsjeContrastChildFn(seriseDataJe);
        }
    },
    methods: {
        closeAlert() {
            this.$emit('closeChildAlert');
        },
        // 业务员销售方量对比
        xsflContrastChildFn(data) {
            const myChart = this.$echarts.init(document.getElementById('xsflContrastChild'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '13%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.childXsflSlotArr,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: {
                    type: 'bar',
                    data: data,
                    label: {
                        fontFamily: 'QuartzLTStd',
                        show: true,
                        position: 'top',
                        color: '#2E9BFC',
                        fontSize: '14',
                        padding: 5,
                    },
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                offset: 0,
                                color: '#3E78C3', // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: '#44A1F5', // 100% 处的颜色
                            }], false),
                        },
                    },
                },
            };
            myChart.setOption(option, true);
        },
        // 业务员销售金额对比
        xsjeContrastChildFn(data) {
            const myChart = this.$echarts.init(document.getElementById('xsjeContrastChild'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '13%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.childXsflSlotArr,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(元)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: {
                    type: 'bar',
                    data: data,
                    label: {
                        fontFamily: 'QuartzLTStd',
                        show: true,
                        position: 'top',
                        color: '#EA8243',
                        fontSize: '14',
                        padding: 5,
                    },
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                offset: 0,
                                color: '#EA8243', // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: '#F0AF56', // 100% 处的颜色
                            }], false),
                        },
                    },
                },
            };
            myChart.setOption(option, true);
        },
    },
};

</script>
<style lang='stylus' scoped>
.xsfl-contrast-box
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    display flex
    z-index 99
    .xsfl-contrast
        background #fff
        width 80%
        padding .4rem 0
        height 4.2rem
        margin-top 1.65rem
        margin-left 3rem
        .font-icon
            width 100%
            display flex
            justify-content flex-end
            padding-right .6rem
            margin-top -.2rem
            span
                width .3rem
                height .3rem
                display flex
                justify-content center
                align-items center
                color #1577D2
                border 1px solid #D7D7D7
                font-size .16rem
                cursor pointer
</style>